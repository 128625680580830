import * as React from 'react';
import {
	Stack,
	IStackStyles,
	IStackTokens,
	IStackItemStyles,
	Image,
	Link,
	useTheme,
	Theme,
	IImageStyles,
} from '@fluentui/react';
import PorscheLogo from '../../../assets/porsche.svg';
import TraceLogo from '../../../assets/radar_logo.svg';
import {TextSearchBar} from 'components';
import {NavigationRight} from './NavigationRight';
import {getConfig} from 'config';

const config = getConfig();

export const Navigation = () => {
	const theme = useTheme();
	const {
		horizontalGapStackTokens,
		logoLinkStyles,
		porscheLogoStyles,
		traceLogoStyles,
		stackStyles,
		testEnv,
	} = getStyles(theme);

	return (
		<Stack
			horizontal
			disableShrink
			horizontalAlign='space-between'
			styles={stackStyles}
			tokens={horizontalGapStackTokens}
		>
			<Stack.Item align='start'>
				<Link href='/' styles={logoLinkStyles}>
					<Image src={TraceLogo} alt='radar_logo' styles={traceLogoStyles} />
					{renderEnvironmentItem(theme)}
				</Link>
			</Stack.Item>
			<Stack.Item>
				<TextSearchBar />
			</Stack.Item>
			<NavigationRight />
		</Stack>
	);
};

const getStyles = (theme: Theme) => ({
	stackStyles: {
		root: {
			background: ' #002733',
			padding: 10,
			height: 70,
		},
	} as IStackStyles,

	horizontalGapStackTokens: {
		padding: 10,
	} as IStackTokens,

	testEnv: {
		root: {
			fontWeight: 'bold',
			paddingLeft: 15,
			color: theme.palette.white,
		},
	} as IStackItemStyles,

	logoLinkStyles: {
		root: {
			color: theme.palette.white,
			padding: 5,
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'center',
			selectors: {
				':hover': {
					textDecoration: 'none',
				},
			},
		},
	} as IStackItemStyles,

	porscheLogoStyles: {
		root: {
			marginRight: 10,
		},
		image: {
			height: 40,
		},
	} as IImageStyles,

	traceLogoStyles: {
		root: {
			marginRight: 10,
			marginLeft: 10,
			marginTop: -10,
		},
		image: {
			height: 65,
		},
	} as IImageStyles,
});

const getBackgroundColor = (theme: Theme) => {
	if (isTestEnvironment()) {
		return theme.palette.green;
	}

	return theme.palette.neutralPrimary;
};

const renderEnvironmentItem = (theme: Theme) => {
	const {testEnv} = getStyles(theme);

	if (isTestEnvironment()) {
		return <Stack.Item styles={testEnv}>Testumgebung</Stack.Item>;
	}

	if (isDevEnvironment()) {
		return <Stack.Item styles={testEnv}>DEV</Stack.Item>;
	}

	return null;
};

export const isTestEnvironment = () => config.REACT_APP_ENV === 'tst';
export const isDevEnvironment = () => config.REACT_APP_ENV === 'pdv';
